<template>
    <div class="lists-no">
        <span :key="langIndex" v-for="(lang, langIndex) in languages" class="flags">
            <vue-country-flag :country='getLanguageFromLabel(lang)' :size='size'/>
            <span v-if="align"><br></span>
        </span>
    </div>
</template>

<script>
    export default {
        name: 'flag-available-content-language',
        props: {
            size: {
                type: String,
                default: "small"
            },

            align: {
                type: Number,
                default: 0
            }
        },

        data() {
            return {
                languages: this.$available_langs.map( (item) => item.code )
            }
        },

        methods: {
            getLanguageFromLabel: function (label) {
                let result = label;
                switch (label) {
                    case "en":
                        result = "gb";
                        break;

                    default:
                        result = label;
                        break;
                }
                return result;
            },
        },
    };
</script>

<style scoped>
    .flags {
        margin:5px 5px 0 0;
        padding: 0;
    }

    .lists-no {
        margin-top: 3px;
        margin-bottom: 5px;
        line-height: 0.25em;
    }
</style>
