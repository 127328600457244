<template>
  <div>
 
    <draggable
      class="list-group"
      element="ul"
      v-model="list"
      :options="dragOptions"
      :move="onMove"
      @start="isDragging=true"
      @end="isDragging=false"
    >
      <transition-group type="transition" :name="'flip-list'">
        <li class="list-group-item" v-for="element in list" :key="element.order">
          <div>
            <i :class="element.icon + ' mr-2'" @click=" element.fixed=! element.fixed" aria-hidden="true"></i>
         <!--  <input type="text" v-model="element.label" readonly>   -->
            <label style="color:#000000;">{{ $t("label.contacts."+element.label) }}</label>
            <a @click="deleteItem(element.name,element.order)" style="text-align:right;float:right;" >
              <i class="fa fa-trash" title="Cancella Contatto"></i>
            </a>
          </div>


         

       
          <input
            type="text"
            v-model="element.name"
            title="clicca per modificare il valore"
            v-on:keyup="changeStatus(list)"
            style="width:100%"
            placeholder="Inserisci Contatto"
               
               
            v-bind:name="buildName(element.name_validation)"
            v-validate="buildValidation(element.name_validation)"

          
           

           
          
          >
          
           
          <p class="text-danger" v-if="errors.has(buildName(element.name_validation)) ">
            <!-- {{ errors.first(buildName(element.name_validation)) }}   -->
              {{customErrorvalidation(errors.first(buildName(element.name_validation)),element.tipo)}}
          </p>
         

         
            
           
          
         
         

         
        </li>
      </transition-group>
    </draggable>
    <a v-if="list.length<max" class="btn btn-primary w-100 my-2" data-toggle="collapse" href="#addContact" role="button" aria-expanded="false" aria-controls="addContact">
      <i class="fa fa-plus"> </i> Aggiungi nuovo contatto        
    </a>
    <div class="collapse" id="addContact">
      <ul class="list-group">
        <li class="list-group-item">
              <a class="dropdown-item" @click="addItem('email')" data-toggle="collapse" href="#addContact">
                <i class="fa fa-envelope mr-2" title="aggiungi email"></i>Email
              </a>
        </li>
        <li class="list-group-item">
          <a class="dropdown-item" @click="addItem('website')" data-toggle="collapse" href="#addContact">
                <i class="fa fa-link mr-2" title="aggiungi sito web"></i>Website
          </a>
        </li>
        <li class="list-group-item">
          <a class="dropdown-item" @click="addItem('telephone')" data-toggle="collapse" href="#addContact">
                <i class="fa fa-phone mr-2" title="aggiungi telefono"></i>Telefono
              </a>
        </li>
        <li class="list-group-item">
          <a class="dropdown-item" @click="addItem('fax')" data-toggle="collapse" href="#addContact">
                <i class="fa fa-fax mr-2" title="aggiungi fax"></i>Fax
              </a>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import draggable from "vuedraggable";
var message = []; /**/

export default {
  name: "altrama-dragable",
  props: {
    elements: {
      type: Array,
      default: function() {
        return [];
      }
    },

    max: {
      type: Number,
      default: 100
    },




    type: {
      type: String,
      default: ""
    }
  } /**/,
  components: {
    draggable
  },
  data() {
    return {
      message: this.elements,
      //adatto l'array che ricevo in input in base alle mie esigenze
      list: this.elements.map((element, index) => {
        //alert(name.valore);
        name = element.valore;
        return {
          name,
          order: index + 1,
          fixed: false,
          tipo: element.tipo,
          valore: element.valore,
          label: "Ricevi da Server",
          icon: this.buildIconClass(element.tipo)
        };
        //return { name, order:element.order, fixed: false, tipo:element.tipo, valore:element.value, label:element.value,icon:this.buildIconClass(element.tipo)};
      }),

      list2: [],
      editable: true,
      isDragging: false,
      delayedDragging: false,

      validate_rule:"required",
      validate_name:"name"
    };
  },

  inject: ["parentValidator"],

  methods: {


    buildName:function(name_validation){
        return '"'+name_validation+'"';
        // return '"'+element.label+'"';
    },

     buildValidation:function(name_validation){

       /* */
    var typeArray=name_validation.split('_');

        
        //https://jsfiddle.net/gcnf5ucn/109/
        //https://jsfiddle.net/gcnf5ucn/110/
        //http://buildregex.com
        
        if(typeArray[0]=="telephone" || typeArray[0]=="fax")
         //return "required|numeric";
         //return "required|regex:^(\\+)?[0-9]+$"; // ok
          return "required|regex:^(\\+)?[0-9/.\\s]+$"; //
          //return ""; //
          //return "required|regex:(?:\+)?[1234567890\ ]";
        if(typeArray[0]=="email")
         return "required|email";
         if(typeArray[0]=="website")
         return "required|url";
         

         return "required";
    },





    changeStatus: function(contatti) {
      
      
      var reductList=[];
      for (var i = 0; i < this.list.length; i++){

       var obj={
            id:this.list[i].id ? this.list[i].id : null,
            label:this.list[i].label,
            order:this.list[i].order,
            value:this.list[i].name,


        };
        reductList.push(obj);
      }
      
      
      this.$emit("changeStatus", reductList);
    },

    addItem(type) {
      var max = 1;
      for (var i = 0; i < this.list.length; i++) {
        var obj = this.list[i];
        if (obj.order > max) max = obj.order;
      }

      this.list.push({
        name: "",//?
        order: max + 1,
        fixed: true,
        tipo: type,//"email",
        valore: "inserisci contatto",
        label: this.buildLabel(type),
        icon: this.buildIconClass(type),
        id:null,
        name_validation:type+"_"+(this.list.length+1)
      });


     
      this.changeStatus(this.list);
    },

    buildLabel(type) {
      var max = 0;
      for (var i = 0; i < this.list.length; i++) {
        var obj = this.list[i];
        if (obj.tipo == type) max = max + 1;
      }
      //if(max>0)
      max = max + 1;

      return type;
      // return type+"_"+max;
    },

    buildIconClass(type) {
      var classIcon = "";
      switch (type) {
        case "email":
          classIcon = "fa fa-envelope";
          this.validate_rule="required|email";
          this.validate_name="name_"+(this.list.length+1);
          break;
        case "website":
          classIcon = "fa fa-link";
          this.validate_rule="required|url";
           this.validate_name="name_"+(this.list.length+1);
          break;

        case "telephone":
          classIcon = "fa fa-phone";
           this.validate_rule="required|alpha_num";
            this.validate_name="name_"+(this.list.length+1);
          break;

        case "fax":
          classIcon = "fa fa-fax";
          this.validate_rule="required|alpha_num";
           this.validate_name="name_"+(this.list.length+1);
          break;
        default:
          break;
      }

      return classIcon;
    },


    customErrorvalidation(error,type){


     
     if(type=='website' || type=='email')
        return error;
      return "Campo obbligatorio. Non può contenere caratteri speciali";
    },

    deleteItem(valore, index) {
      if (confirm("Confermi la rimozione del contatto " + valore)) {
        //this.list.splice(index, 1);

        const filteredItems = this.list.filter(function(item) {
          return item.name !== valore;
        });
        this.list = filteredItems;

        this.changeStatus(this.list);
      }
    },

    orderList() {
      //alert("order");
      this.list = this.list.sort((one, two) => {
        return one.order - two.order;
      });
    },
    onMove({ relatedContext, draggedContext }) {
      //alert("move");

      const relatedElement = relatedContext.element;
      const draggedElement = draggedContext.element;
      return (
        (!relatedElement || !relatedElement.fixed) && !draggedElement.fixed
      );
    }
  },
  computed: {
    dragOptions() {
      return {
        animation: 0,
        group: "description",
        disabled: !this.editable,
        ghostClass: "ghost"
      };
    },
    listString() {
      return JSON.stringify(this.list, null, 2);
    },
    list2String() {
      return JSON.stringify(this.list2, null, 2);
    },
    
  },
  watch: {
    isDragging(newValue) {
      if (newValue) {
        this.delayedDragging = true;
        return;
      }
      this.$nextTick(() => {
        this.delayedDragging = false;
      });
      this.changeStatus(this.list);
    },

    elements: function() {
    

      this.list = this.elements.map((element, index) => {
        //alert(element.value);
        name = element.value;
        element.tipo = "";
        //
        switch (element.label) {
          case "telephone":
            element.tipo = "telephone";
            break;
          case "email":
            element.tipo = "email";
            break;
          case "website":
            element.tipo = "website";
            break;
          case "fax":
            element.tipo = "fax";
            break;
          default:
            break;
        }
        //

        return {
          name,
          order: element.order,
          fixed: true,
          tipo: element.tipo,
          valore: element.value,
          label: element.label,
          id:element.id,
          icon: this.buildIconClass(element.tipo),
          name_validation:element.tipo+"_"+element.id
        };
      });
      /* */
      this.changeStatus(this.list); //notifico al supercomponent anche in fase di edit qualora non modifico i dati del componente relativo
    }
  },
  created() {
      this.$validator = this.parentValidator;
    
  },
  mounted() {
   
  }
};
</script>

<style scoped>
.btn {
  margin: 0px;
}
.flip-list-move {
  transition: transform 0.5s;
}

.no-move {
  transition: transform 0s;
}

.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}

.list-group {  
  width: auto;
}

.list-group-item {
  cursor: move;
  width: 100%;
  min-height: 60px;
}

.list-group-item i {
  cursor: pointer;
}

.dropdown-menu {
  position: relative;
}
</style>
