<template>
    <div class="btn-toolbar" role="toolbar" aria-label="Bottoni azione">
       <div class="btn-group btn-group-sm mr-2" role="group" aria-label="Azioni">
            <a v-if="name=='Download'" title="Download" class="btn btn-primary btn-sm"
                         :href="url" target="_blank">
                <span class="fa fa-download"></span>
            </a>



            <button  v-if="edit_item && type=='category'"
                    title="Modifica" type="button"
                    class="btn btn-primary btn-sm" @click="dialogCategory(id)">
                <span class="fa fa-pencil"></span>
            </button>


            <router-link v-if="edit_item && type!='category'" title="Modifica" tag="a" type="button" class="btn btn-primary btn-sm"
                         :to="{ name: name, params: calculateParameter()}" >
                <span class="fa fa-pencil"></span>
            </router-link>

            <router-link v-if="revision > 0" title="Revisione" tag="a" type="button" class="btn btn-secondary btn-sm"
                         :to="{ name: name, params: calculateParameterDraft()}">
              <span>Revisione</span>
            </router-link>


            <button title="Elimina" v-if="delete_item" type="button" class="btn btn-danger btn-sm"
                    @click="deleteAction()">
                <span class="fa fa-trash"><img v-if="isLoading" src="@/assets/img/spinner.gif"
                                               alt="loading" width="16" height="16" class="ml-2">
                </span>
            </button>

            <span class="fa fa-minus-circle" style="color:red;" v-if="no_action"></span>
        </div>
    </div>
</template>

<script>
    import axios from 'axios';

    export default {

        name: 'action-table',
        props: {
            path: String,
            url: String,
            id: Number,
            name: String,
            draft: {
              type: Number,
              default: 0
            },
          revision: {
            type: Number,
            default: 0
          },
            type: {
                type: String,
                default: ""
            },
            theme: {
                type: Number,
                default: 5
            },
        },

        data() {
            return {
                edit_item: "",
                delete_item: "",
                no_action: false,
                isLoading: false
            }
        },

        methods: {
            deleteAction: function () {
                if (confirm('Confermare cancellazione elemento selezionato?')) {
                    var elenco = this.id;
                    var self = this;
                    self.isLoading = true;
                    axios({
                        method: 'delete',
                        url: this.$url + self.path + '/' + elenco,
                        headers: this.$config.axiosHeaders(),
                    }).then(function (response) {
                        self.$notifyVue('top', 'center', response.data.status, response.data.data.message, "ti-check");
                        location.reload();
                    }).catch(function (error) {
                        self.$processError(error);

                    }).finally(
                        () => (self.isLoading = false)
                    );
                } // confirm
            },

            dialogCategory(id) {
                this.$emit("dialog-category", id);
            },

            calculateParameter() {
                if (this.type == "interessi")
                    return {id: this.id, path: this.path, theme: this.theme};
                return {id: this.id, path: this.path};
            },

          calculateParameterDraft() {
            if (this.type == "interessi")
              return {id: this.revision, path: this.path, theme: this.theme};
            return {id: this.revision, path: this.path};
          }
        },
        created: async function () {
            switch (this.path) {
                case "user":
                case "event":
                case "news":
                case "iat":
                case "webcam":
                case "itinerary":
                case "destination":
                case "interessi":
                case "page":
                case "download":
                case "opendata":
                    this.edit_item = await this.$api.user.can(this.path + "-edit");
                    this.delete_item = await this.$api.user.can(this.path + "-delete");
                    break;
                case "categories":
                case "home":
                    this.edit_item = await this.$api.user.can(this.path + "-edit");
                    this.delete_item = false;//this.$api.user.can(this.path+"-delete");
                    break;
                case "redazioni":
                    this.edit_item = true;
                    this.delete_item = false;
                    break;
                case "operatori":
                    this.edit_item = await this.$api.user.can( "operatori-edit");
                    this.delete_item =await this.$api.user.can("operatori-delete");
                    break;
                case "blog":
                    this.edit_item =await this.$api.user.can( "blog-edit");
                    this.delete_item = await this.$api.user.can("blog-delete");
                    break;
                case "soggiorno":
                    this.edit_item =await this.$api.user.can( "soggiorno-edit");
                    this.delete_item =await this.$api.user.can("soggiorno-delete");
                    break;
                default://in core tante rotte sono definite al singolare con section plurare. Esempio event-events
                    this.edit_item =await this.$api.user.can(this.path + "s-edit");
                    this.delete_item =await this.$api.user.can(this.path + "s-delete");
                    break;
            }

            if (!this.edit_item && !this.delete_item)
                this.no_action = true;
        }
    }
</script>
