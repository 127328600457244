<template>
  <div class="gallery row justify-content-md-center">
    <div class="col-auto" v-for="(image, imageIndex) in imageAdapted" :key="imageIndex">
      <a v-on:click="changeMedia(image.id)">
        <div :class="image.class + ' item'">
          <div
            :style="{ backgroundImage: 'url(' + image.url_thumb +')' }"
            alt="Fai click per visualizzare i dettagli"
            class="gallery-thumb attachment-thumbnail"
          ></div>
        </div>
      </a>
      <button
        v-if="multipleSelected==true"
        type="button"
        :class="'check ' + image.button_class"
        tabindex="0"
        v-on:click="seleziona(image.id)"
      >
        <i v-if="image.button_class=='checked'" class="fa fa-check" aria-hidden="true" title="clicca per selezionare/deselezionare"></i>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "mapspartout-gallery",
  props: {
    images: {
      type: Array,
      default: function() {
        return [];
      }
    },

    initialSelected: {
      type: Array,
      default: function() {
        return [];
      }
    },

    multipleSelected: {
      default: true
    },

    focus: {
      type: Number,
      default: 0
    }
  },

  data: function() {
    return {
      imageAdapted: [],
      index: null,
      isSelectedMedia: "focus", //servirà per cambiare classe dinamicamente ad ogni singolo media
      yes_focusArray: [], //array boolean per stabilire le classi di quelle selezionate
      current_focus: -1 //indice del focus corrente
    };
  },
  watch: {
    images: function() {
      this.imageAdapted = this.images;
      if (this.focus > 0 && this.imageAdapted.length > 0) {
        this.changeMedia(this.focus);
      }
    },
    focus: function(v2, v1) {
      this.changeMedia(v2);
    }
  },

  methods: {
    isPDF: function(object) {
      var url = object.url;
      var filename = url.substring(url.lastIndexOf("/") + 1);
      var elements = filename.split(".");
      if (elements[1] == "pdf") return true;

      return false;
    },

    selectImage: function(index) {
      //emit segnale per supercomponente che ospita la gallery
    },

    changeMedia: function(index) {
      var index2 = -1;

      for (var i = 0; i < this.imageAdapted.length; i++)
        if (this.imageAdapted[i].id == index) {
          index2 = i;
          break;
        }

      for (var i = 0; i < this.imageAdapted.length; i++)
        if (i != index2) this.imageAdapted[i].class = "";

      if (this.imageAdapted[index2].class == "focus") {
        this.$emit("changeMedia", -1);
        this.imageAdapted[index2].class = "";
      } else {
        this.$emit("changeMedia", this.images[index2]);
        this.imageAdapted[index2].class = "focus";
      }
    },

    changeMediaFromTable: function(index) {
      var index2 = -1;
      for (var i = 0; i < this.imageAdapted.length; i++)
        if (this.imageAdapted[i].id == index) {
          index2 = i;
          break;
        }

      for (var i = 0; i < this.imageAdapted.length; i++)
        if (i != index2) this.imageAdapted[i].class = "";

      this.$emit("changeMedia", this.images[index2]);
      if (this.imageAdapted[index2]) this.imageAdapted[index2].class = "focus";
    },

    onSelectMultipleImage: function() {},

    isActived: function(index) {
      return this.yes_focusArray[index];
    },

    seleziona: function(index) {
      var index2 = -1;
      for (var i = 0; i < this.imageAdapted.length; i++)
        if (this.imageAdapted[i].id == index) {
          index2 = i;
          break;
        }
      if (this.initialSelected.includes(index) == false) {
        this.initialSelected.push(index);
        this.imageAdapted[index2].button_class = "checked";
      } //remove
      else {
        var index_R = this.initialSelected.indexOf(index);
        this.initialSelected.splice(index_R, 1);
        this.imageAdapted[index2].button_class = "";
      }
      var imageAdaptedSend = [];
      for (var i = 0; i < this.imageAdapted.length; i++) {
        var object = this.imageAdapted[i];
        if (object.button_class == "checked") imageAdaptedSend.push(object);
      }

      this.$emit("selectedAllegati", imageAdaptedSend);
    }
  },
  mounted: function() {
    this.imageAdapted = this.images;
    if (this.focus > 0 && this.imageAdapted.length > 0) {
      this.changeMedia(this.focus);
    }
  }
};
</script>

<style lang="scss" scoped>
.attachment-thumbnail {
  width: 200px;
  height: 200px;
  background-position: center;
    background-size: contain;
    background-color: lightgrey;
    background-repeat: no-repeat;
}

.gallery {
  min-height: 400px;

  .item {
    position: relative;
    margin-bottom: 30px;

    img {
      width: 200px;
      height: 200px;
      background-color: #929090;
    }

    transition: box-shadow 100ms ease-in;

    &.focus {
      /* border: 4px solid rgb(185, 224, 11);
          margin: -4px;
          margin-bottom: 26px; */
      box-shadow: 0px 0px 4px 4px #b9e00b;
    }
  }
}

div.yes_focus_ {
  box-shadow: 0px 0px 4px 4px #b9e00b;
  transition: box-shadow 300ms linear;
}

div.no_focus_ {
  border: none;
  margin: 0;
  margin-bottom: 30px;
}

div.desc {
  padding: 15px;
  text-align: center;
}

.check {
  height: 24px;
  width: 24px;
  padding: 0;
  border: 2px solid grey;
  position: absolute;
  z-index: 10;
  top: 10px;
  right: 25px;
  outline: 0;
  background-color: #fff;
  color: #fff;
  cursor: pointer;
  transition: opacity 50ms linear;
  opacity: 0.7;

  &.checked {
    background-color: #fff;
    color: #444;
    opacity: 1;
  }
}
</style>
