<template>
  <div>
    <div>
      <vue-dropzone
        ref="myVueDropzone"
        id="dropzone"
        :options="dropzoneOptions"
        :useCustomSlot="true"
        @vdropzone-complete="afterComplete"
        v-on:vdropzone-error="failed"
        @thumbnail="thumbnail"
        @vdropzone-queue-complete="addListAttachments"
      >
        <div class="dropzone-custom-content">
          <h3 class="dropzone-custom-title">
            Trascina qui un file per caricarlo
          </h3>
          <div class="subtitle">
            ...oppure fai click per caricarne uno dal tuo computer
          </div>
        </div>
        <p class="mt-2" v-if="alertImage">
          <span style="color: gray"> Per le immagini assicurarsi che il lato più lungo sia almeno di {{ minSize }}px </span>
        </p>
        <p class="mt-2" v-else>
          <span style="color: gray">{{ msgType }}</span>
        </p>
      </vue-dropzone>
    </div>
  </div>
</template>

<style>
.example-drag label.btn {
  margin-bottom: 0;
  margin-right: 1rem;
}

.example-drag .drop-active {
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  position: fixed;
  z-index: 9999;
  opacity: 0.6;
  text-align: center;
  background: #000;
}

.example-drag .drop-active h3 {
  margin: -0.5em 0 0;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 40px;
  color: #fff;
  padding: 0;
}
</style>

<script>
import axios from "axios";

var height = 0;
var width = 0;
var minSize = 0;
var acceptInput = "";
var type = "";
var everything = false;

export default {
  name: "altrama-upload-component",
  props: {
    minImageWidth: {
      type: Number,
      default: 800,
    },
    minImageHeight: {
      type: Number,
      default: 600,
    },
    minSize: {
      type: Number,
      default: 1200,
    },
    acceptedFileInput: {
      type: String,
      default: "image/*",
    },
    alertImage: {
      type: Boolean,
      default: true,
    },
    type: {
      type: String,
      default: "",
    },
    msgType: {
      type: String,
      default: "",
    },

    collectionType: {
      type: String,
      default: "default",
    },
    id_parent: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      self: this,
      height: 0,
      width: 0,
      files: [],
      thumbnail: "",
      post_action_data: this.$url + "media" + "/upload",
      my_header_data: [],
      dropzoneOptions: {
        url: this.$url + "media" + "/upload",
        thumbnailWidth: 150,
        maxFilesize: 100,
        timeout: 300000,
        addRemoveLinks: true,
        createImageThumbnails: true,
        headers: this.$config.axiosHeaders(),

        accept: function (file, done) {
          if (file.type.indexOf("svg") > -1) {
            return done();
          }

          if (
            everything ||
            file.type == "application/pdf" ||
            file.type == "application/json" ||
            file.type == "text/xml" ||
            file.type == "application/vnd.ms-excel" ||
            file.type == "text/csv"
          ) {
            return done();
          }

          file.acceptDimensions = done;
          file.pdf = done;
          file.rejectDimensions = function () {
            alert(
              "Impossibile caricare il file. Verificare anche il tipo di file caricato. Per le immagini assicurarsi che il lato più lungo sia almeno di " +
                minSize +
                "px."
            );
            done(
              "Impossibile caricare il immagine. Verificare anche il tipo di file caricato. Assicurarsi che il lato più lungo sia almeno di " +
                minSize +
                "px"
            );
          };
        },

        init: function () {
          this.on("thumbnail", function (file) {
            if (file.type.indexOf("image") > -1) {
              if (file.type.indexOf("svg") > -1) {
                return;
              }

              var max = file.width;
              if (file.height > max) max = file.height;

              if (max < minSize) {
                this.removeFile(file);

                file.rejectDimensions();
              } else file.acceptDimensions();
            } //controllo immagini
          });
        },
      },
    };
  },

  created: function () {
    width = this.minImageWidth;
    height = this.minImageHeight;
    minSize = this.minSize;
    acceptInput = this.acceptedFileInput;
    type = this.type;
    this.width = this.minImageWidth;
    this.height = this.minImageHeight;
    this.my_header_data = this.my_header();
    this.dropzoneOptions.headers = this.my_header();

   if (acceptInput != "*"){
      this.dropzoneOptions.acceptedFiles = this.acceptedFileInput;
   }

   this.dropzoneOptions.url =
      this.$url +
      "media/upload/" +
      this.collectionType + "/" + this.id_parent;

    if (acceptInput == "*") everything = true;
    if (this.collectionType == "images") this.dropzoneOptions.maxFilesize = 5;
  },

  methods: {
    failed:function(file,message,xhr){
      let response = xhr.response;
      let parse = JSON.parse(response, (key, value)=>{
        return value;
      });
      $('.dz-error-message span').text(parse.message);
    },

    afterComplete(file) {
      if (file.status == "success") {
        this.$emit("new-upload", true);
      }
    },

    addListAttachments(file) {
      this.$emit("add-list-attachment", true);
    },

    removeAllFiles() {
      this.$refs.dropzone.removeAllFiles();
    },

     my_header() {
               return this.$config.axiosHeadersUpload();
            },
  },
};
</script>


<style lang="scss">
/* stili app */
.vue-notifyjs.notifications {
  .alert {
    z-index: 10000;
  }

  .list-move {
    transition: transform 0.3s, opacity 0.4s;
  }

  .list-item {
    display: inline-block;
    margin-right: 10px;
  }

  .list-enter-active {
    transition: transform 0.2s ease-in, opacity 0.4s ease-in;
  }

  .list-leave-active {
    transition: transform 1s ease-out, opacity 0.4s ease-out;
  }

  .list-enter {
    opacity: 0;
    transform: scale(1.1);
  }

  .list-leave-to {
    opacity: 0;
    transform: scale(1.2, 0.7);
  }
}
</style>
